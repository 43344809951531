import { all } from 'redux-saga/effects';
import neProfile from './neProfile/sagas';
import veProfile from './veProfile/sagas';
import device from './device/sagas';
import profileDevice from './profileDevice/sagas';
import user from './user/sagas';
import firmware from './firmware/sagas';
import version from './version/sagas';

export default function* rootSaga() {
  yield all([neProfile(), veProfile(), device(), profileDevice(), user(), firmware(), version()]);
}
