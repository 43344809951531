const actions = {
  SET_STATE: 'version/SET_STATE',
  LIST: 'version/LIST',
  COUNT: 'version/COUNT',
  CREATE: 'version/CREATE',
  UPDATE: 'version/UPDATE',
  DETAIL: 'version/DETAIL',
  DELETE: 'version/DELETE'
};

export default actions;
