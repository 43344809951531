const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOGOUT: 'user/LOGOUT',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  DETAIL: 'user/DETAIL',
  UPDATE: 'user/UPDATE'
};

export default actions;
