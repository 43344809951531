import Icon from '@mui/material/Icon';
import { lazy } from 'react';

const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    component: lazy(() => import('pages/dashboard')),
    noCollapse: true,
    protected: true
  },
  {
    type: 'none',
    key: 'devices',
    route: '/devices/:id/detail',
    component: lazy(() => import('pages/device/detail')),
    noCollapse: true,
    protected: true
  },
  {
    type: 'collapse',
    name: 'Devices',
    key: 'devices',
    icon: <Icon fontSize="small">devices</Icon>,
    route: '/devices',
    component: lazy(() => import('pages/device')),
    noCollapse: true,
    protected: true
  },
  {
    type: 'none',
    key: 'firmwares',
    route: '/firmwares/:id/detail',
    component: lazy(() => import('pages/firmware/detail')),
    noCollapse: true,
    protected: true
  },
  {
    type: 'collapse',
    name: 'Firmwares',
    key: 'firmwares',
    icon: <Icon fontSize="small">memory</Icon>,
    route: '/firmwares',
    component: lazy(() => import('pages/firmware')),
    noCollapse: true,
    protected: true
  },
  {
    type: 'none',
    key: 'customers',
    route: '/customers/:id/detail',
    component: lazy(() => import('pages/customer/detail')),
    noCollapse: true,
    protected: true
  },
  {
    type: 'collapse',
    name: 'Customers',
    key: 'customers',
    icon: <Icon fontSize="small">group</Icon>,
    route: '/customers',
    component: lazy(() => import('pages/customer')),
    noCollapse: true,
    protected: true
  },
  {
    type: 'collapse',
    name: 'Profile',
    key: 'profile',
    icon: <Icon fontSize="small">person</Icon>,
    route: '/profile',
    component: lazy(() => import('pages/profile')),
    noCollapse: true,
    protected: true
  },
  {
    type: 'none',
    key: 'login',
    route: '/login',
    component: lazy(() => import('pages/authentication/sign-in')),
    noCollapse: true
  },
  {
    type: 'none',
    key: '404',
    route: '/404',
    component: lazy(() => import('pages/errors/404')),
    noCollapse: true
  }
];

export default routes;
