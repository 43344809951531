export const countPaginationRange = (meta, entity) => `Showing ${
  meta.totalItems > 0 ? meta.pageSize * meta.page - meta.pageSize + 1 : 0
} -
${meta.pageSize * meta.page > meta.totalItems ? meta.totalItems || 0 : meta.pageSize * meta.page || 0}
OF ${meta.totalItems || 0} ${entity} `;

export const replaceUrlIds = (url, ids) => {
  const splitUrl = url.split('/');
  const newSplitedUrl = [];

  splitUrl.forEach((element) => {
    if (element.startsWith('{') && element.endsWith('}')) {
      const elementName = element.slice(1, -1);
      const getId = ids.find((x) => x[elementName]);
      if (getId) {
        newSplitedUrl.push(getId[elementName]);
        return;
      }
    }
    newSplitedUrl.push(element);
    return;
  });

  return newSplitedUrl.join('/');
};

export const checkDefault = (v, def) => (v === null || v === '' ? def : v);
