import { all, takeEvery, call, put } from 'redux-saga/effects';
import { list, count, create, remove, detail, update, unassignedCount, unassignedDevices } from 'services/apis/device';
import { toastr } from 'react-redux-toastr';
import actions from './actions';

function* loading(isLoading = false, error = false) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: isLoading,
      error
    }
  });
}

export function* LIST({ payload }) {
  yield loading(true);

  const response = yield call(list, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        list: response,
        isDeviceFetched: true,
        isCountFetched: false
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* COUNT({ payload }) {
  yield loading(true);

  const response = yield call(count, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        count: response.count,
        isDeviceFetched: false,
        isCountFetched: true
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* CREATE({ payload }) {
  const { body, filter } = payload;
  let whereCondition = {};
  yield loading(true);

  if (filter.where) {
    whereCondition = filter.where;
  }

  const response = yield call(create, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false,
        isDeviceFetched: false,
        isCountFetched: false
      }
    });

    yield put({ type: actions.COUNT, payload: { where: whereCondition } });
    toastr.success('succeeded', 'created new device!');
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* DELETE({ payload }) {
  const { deviceIds, filter } = payload;
  let whereCondition = {};
  yield loading(true);

  if (filter.where) {
    whereCondition = filter.where;
  }

  const response = yield call(remove, { deviceIds: JSON.stringify(deviceIds) });
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: { success: true },
        loading: false,
        error: false,
        isRemoved: true,
        isDeviceFetched: false,
        isCountFetched: false
      }
    });

    yield put({ type: actions.COUNT, payload: { where: whereCondition } });
    toastr.success('succeeded', 'device removed!');
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* DETAIL({ payload }) {
  const { id } = payload;
  let filter = {};
  yield loading(true);

  if (payload.filter) {
    filter = payload.filter;
  }

  const response = yield call(detail, id, filter);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* UPDATE({ payload }) {
  const { id, body, filter } = payload;
  yield loading(true);

  const response = yield call(update, id, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false,
        isDeviceFetched: false,
        isCountFetched: false
      }
    });
    if (filter.where) {
      yield put({ type: actions.COUNT, payload: { where: filter.where } });
    } else {
      yield put({ type: actions.LIST, payload: { filter: JSON.stringify(filter) } });
    }

    toastr.success('succeeded', 'device updated!');
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* UNASSIGNED_LIST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      unassignedLoading: true,
      error: false
    }
  });

  const response = yield call(unassignedDevices, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        unassignedLoading: false,
        error: false,
        unassignedList: response,
        isUnassignedDeviceFetched: true,
        isUnassignedCountFetched: false
      }
    });
  }

  if (!response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        unassignedLoading: false,
        error: true
      }
    });
  }
}

export function* UNASSIGNED_COUNT({ payload }) {
  yield loading(true);

  const response = yield call(unassignedCount, payload);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        error: false,
        unassignedCount: response.count,
        isUnassignedCountFetched: true
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* CLEAR_DETAIL() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      detail: {},
      loading: false,
      error: false
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.COUNT, COUNT),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DELETE, DELETE),
    takeEvery(actions.DETAIL, DETAIL),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UNASSIGNED_LIST, UNASSIGNED_LIST),
    takeEvery(actions.UNASSIGNED_COUNT, UNASSIGNED_COUNT),
    takeEvery(actions.CLEAR_DETAIL, CLEAR_DETAIL)
  ]);
}
