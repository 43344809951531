const actions = {
  SET_STATE: 'veProfile/SET_STATE',
  LIST: 'veProfile/LIST',
  COUNT: 'veProfile/COUNT',
  DETAIL: 'veProfile/DETAIL',
  CREATE: 'veProfile/CREATE',
  DELETE: 'veProfile/DELETE',
  UPDATE: 'veProfile/UPDATE',
  CLEAR_DETAIL: 'veProfile/CLEAR_DETAIL'
};

export default actions;
