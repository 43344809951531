import './styles.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from 'App';
import { MaterialUIControllerProvider } from 'context';
import { Provider } from 'react-redux';
import { store, history } from 'redux/store';
import ReduxToastr from 'react-redux-toastr';
import packageJson from '../package.json';
import config from './config/config';

// eslint-disable-next-line no-console
console.info(`
########################################################
# Frontend version : ${packageJson.version}
# BACKEND URL      : ${config.API_ENDPOINT}
########################################################
`);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <MaterialUIControllerProvider>
        <App />
        <ReduxToastr
          timeOut={5000}
          closeOnToastrClick
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          preventDuplicates
        />
      </MaterialUIControllerProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
