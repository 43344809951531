import actions from './actions';

const initialState = {
  data: {},
  status: {},
  loading: false,
  isLogin: false,
  detail: {},
  error: false
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
