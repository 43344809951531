import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'auth-context/auth.context';

export const ProtectedRoute = ({ ...rest }) => {
  const { user } = useAuth();
  if (!user || !user.token || user.token === '') {
    return <Redirect to="/login" />;
  }

  return <Route {...rest} />;
};
