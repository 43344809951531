import apiClient from 'services/axios';
import queryString from 'query-string';
import { replaceUrlIds } from 'utils';

const API = {
  list: 'api/devices',
  create: 'api/devices',
  count: 'api/devices/count',
  delete: 'api/devices/batch',
  detail: 'api/devices/{deviceId}',
  update: 'api/devices/{deviceId}',
  unassignedDevices: 'api/devices/unassigned',
  unassignedCount: 'api/devices/unassigned/count'
};

export const list = async (filter) => {
  const response = await apiClient.get(API.list, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const count = async (filter) => {
  const response = await apiClient.get(API.count, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const create = async (body) => {
  const response = await apiClient.post(API.create, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const remove = async (deviceIds) => {
  const response = await apiClient.delete(API.delete, {
    params: deviceIds,
    paramsSerializer: (params) => {
      return queryString.stringify(params);
    }
  });
  if (response.status === 204) {
    return true;
  }
  return false;
};

export const detail = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.detail, [{ deviceId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const update = async (id, body) => {
  const parsedUrl = replaceUrlIds(API.update, [{ deviceId: id }]);
  const response = await apiClient.patch(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const unassignedDevices = async (filter) => {
  const response = await apiClient.get(API.unassignedDevices, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const unassignedCount = async (filter) => {
  const response = await apiClient.get(API.unassignedCount, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};
