import apiClient from 'services/axios';
import { replaceUrlIds } from 'utils';
import store from 'store';

const API = {
  login: '/api/users/login',
  logout: '/api/users/logout',
  resetPassword: 'api/users/{userId}/changePassword',
  detail: 'api/users/{userId}',
  update: 'api/users/{userId}'
};

export const login = async (email, password) =>
  apiClient
    .post(API.login, { email, password })
    .then((response) => {
      if (response) {
        const { userId, id } = response.data;
        store.set('token', id);
        store.set('userId', userId);
        return response.data;
      }
      return false;
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

export const logout = async () =>
  apiClient
    .post(API.logout)
    .then((res) => res.data)
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));

export const resetPassword = async (id, body) => {
  const parsedUrl = replaceUrlIds(API.resetPassword, [{ userId: id }]);
  const response = await apiClient.post(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const detail = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.detail, [{ userId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const update = async (id, body) => {
  const parsedUrl = replaceUrlIds(API.update, [{ userId: id }]);
  const response = await apiClient.patch(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};
