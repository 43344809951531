const actions = {
  SET_STATE: 'firmware/SET_STATE',
  LIST: 'firmware/LIST',
  COUNT: 'firmware/COUNT',
  CREATE: 'firmware/CREATE',
  DETAIL: 'firmware/DETAIL',
  UPDATE: 'firmware/UPDATE',
  DELETE: 'firmware/DELETE',
  CLEAR_DETAIL: 'firmware/CLEAR_DETAIL'
};

export default actions;
