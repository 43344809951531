import apiClient from 'services/axios';
import { replaceUrlIds } from 'utils';

const API = {
  list: 'api/firmwares/{firmwareId}/versions',
  count: 'api/firmwares/{firmwareId}/versions/count',
  update: 'api/firmwares/{firmwareId}/versions/{versionId}',
  delete: 'api/firmwares/{firmwareId}/versions/{versionId}'
};

export const list = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.list, [{ firmwareId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const count = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.count, [{ firmwareId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const update = async (firmwareId, versionId, body) => {
  const parsedUrl = replaceUrlIds(API.update, [{ firmwareId }, { versionId }]);
  const response = await apiClient.patch(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const remove = async (firmwareId, versionId) => {
  const parsedUrl = replaceUrlIds(API.delete, [{ firmwareId }, { versionId }]);
  const response = await apiClient.delete(parsedUrl);
  if (response) {
    return response.data;
  }
  return false;
};
