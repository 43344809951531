import apiClient from 'services/axios';
import { replaceUrlIds } from 'utils';

const API = {
  list: 'api/firmwares',
  count: 'api/firmwares/count',
  create: 'api/firmwares',
  detail: 'api/firmwares/{firmwareId}',
  update: 'api/firmwares/{firmwareId}',
  delete: 'api/firmwares/{firmwareId}'
};

export const list = async (filter) => {
  const response = await apiClient.get(API.list, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const count = async (filter) => {
  const response = await apiClient.get(API.count, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const create = async (body) => {
  const response = await apiClient.post(API.create, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const detail = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.detail, [{ firmwareId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const update = async (id, body) => {
  const parsedUrl = replaceUrlIds(API.update, [{ firmwareId: id }]);
  const response = await apiClient.patch(parsedUrl, body);
  if (response) {
    return response.data;
  }
  return false;
};

export const remove = async (id) => {
  const parsedUrl = replaceUrlIds(API.delete, [{ firmwareId: id }]);
  const response = await apiClient.delete(parsedUrl);
  if (response) {
    return response.data;
  }
  return false;
};
