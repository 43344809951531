import actions from './actions';

const initialState = {
  list: [],
  detail: {},
  count: 0,
  loading: false,
  error: false,
  isRemoved: false,
  isDeviceFetched: false,
  isCountFetched: false,
  unassignedList: [],
  unassignedCount: 0,
  unassignedLoading: false,
  isUnassignedDeviceFetched: false,
  isUnassignedCountFetched: false
};

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
