import { applyMiddleware, createStore, compose } from 'redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import reducers from 'redux/reducers';
import sagas from './sagas';

const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers(history), composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(sagas);

export { store, history };
