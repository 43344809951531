import React from 'react';
import PropTypes from 'prop-types';
import globalStore from 'store';

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const accessToken = globalStore.get('token');
  let user = { token: accessToken ? accessToken : '' };

  return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.any
};

export const useAuth = () => React.useContext(AuthContext);
