import axios from 'axios';
import config from 'config/config';
import store from 'store';
import { get } from 'lodash';
import { toastr } from 'react-redux-toastr';
import ERROR from 'constant/error';
import { history } from 'redux/store';

const baseURL = config.API_ENDPOINT;
const apiClient = axios.create({
  baseURL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});
apiClient.interceptors.request.use(
  (request) => {
    const accessToken = store.get('token');
    if (accessToken) {
      request.headers = {
        ...request.headers,
        authorization: accessToken
      };
    }
    store.set('offline', false);
    return request;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response } = error;
    const errorObj = JSON.parse(JSON.stringify(error));
    // eslint-disable-next-line no-console
    console.info('#####INTERCEPTORS#####', response, error);
    if (!response) {
      store.set('offline', true);
      if (errorObj && !errorObj.status && errorObj.config && errorObj.config.timeout === 600000) {
        toastr.warning('Error', 'Payload Too Large');
      }
      return;
    }

    let { data, status } = response;
    let name = '';
    let message = '';
    let code = '';

    if (data && data.error) {
      let resError;
      if (data.error.details && data.error.details.length) {
        resError = data.error.details[0];
        status = data.error.details[0].statusCode;
      } else {
        resError = get(data, 'error');
      }
      message = get(resError, ['message'], '');
      name = get(resError, 'name');
      code = get(resError, ['code'], '');
    } else {
      code = get(error, ['code'], '');
      message = get(data, 'message');
      name = get(data, 'name');
    }

    const errorLib = ERROR[code];
    if (errorLib) {
      message = errorLib.message;
      if (errorLib.name) {
        name = errorLib.name;
      }
    }

    if (status === 401) {
      store.clearAll();
      history.push('/login');
      toastr.warning(name, message);
      return;
    }

    if (status === 404) {
      toastr.warning(`status ${status}`, error.message);
      return;
    }

    if (code === 'ERR_NETWORK') {
      toastr.warning(code, error.message);
      return;
    }

    if (status === 500) {
      toastr.warning('Error', 'Internal Server Error');
      return;
    }

    toastr.warning(name, message);

    if (status >= 500) {
      throw error;
    }
  }
);

export default apiClient;
