const actions = {
  SET_STATE: 'device/SET_STATE',
  LIST: 'device/LIST',
  COUNT: 'device/COUNT',
  CREATE: 'device/CREATE',
  UPDATE: 'device/UPDATE',
  DELETE: 'device/DELETE',
  DETAIL: 'device/DETAIL',
  UNASSIGNED_LIST: 'device/UNASSIGNED_LIST',
  UNASSIGNED_COUNT: 'device/UNASSIGNED_COUNT',
  CLEAR_DETAIL: 'device/CLEAR_DETAIL'
};

export default actions;
