const actions = {
  SET_STATE: 'neProfile/SET_STATE',
  LIST: 'neProfile/LIST',
  COUNT: 'neProfile/COUNT',
  CREATE: 'neProfile/CREATE',
  UPDATE: 'neProfile/UPDATE',
  DETAIL: 'neProfile/DETAIL',
  DELETE: 'neProfile/DELETE'
};

export default actions;
