import { all, takeEvery, call, put } from 'redux-saga/effects';
import { login, logout, resetPassword, detail, update } from 'services/apis/user';
import { history } from 'redux/store';
import { toastr } from 'react-redux-toastr';
import actions from './actions';
import store from 'store';

function* loading(isLoading = false) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: isLoading
    }
  });
}

export function* LOGIN({ payload }) {
  const { email, password } = payload;
  yield loading(true);

  const response = yield call(login, email, password);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: response,
        loading: false,
        isLogin: true
      }
    });
    yield history.push('/');
  }

  if (!response) {
    yield loading(false);
  }
}

export function* LOGOUT() {
  yield call(logout);
  yield put({
    type: actions.SET_STATE,
    payload: {
      isLogin: false,
      loading: false,
      data: {}
    }
  });

  store.clearAll();
  yield history.push('/login');
}

export function* RESET_PASSWORD({ payload }) {
  const { id, body } = payload;
  yield loading(true);

  const response = yield call(resetPassword, id, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        status: response,
        loading: false,
        isLogin: false
      }
    });
    store.clearAll();
    yield history.push('/login');
    toastr.success('succeeded', 'password reset please login again!');
  }

  if (!response) {
    yield loading(false);
  }
}

export function* DETAIL({ payload }) {
  const { id } = payload;
  let filter = {};
  yield loading(true);

  if (payload.filter) {
    filter = payload.filter;
  }

  const response = yield call(detail, id, filter);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false
      }
    });
  }

  if (!response) {
    yield loading(false, true);
  }
}

export function* UPDATE({ payload }) {
  const { id, body } = payload;
  yield loading(true);

  const response = yield call(update, id, body);
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        detail: response,
        loading: false,
        error: false
      }
    });

    toastr.success('succeeded', 'user updated!');
  }

  if (!response) {
    yield loading(false, true);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.DETAIL, DETAIL),
    takeEvery(actions.UPDATE, UPDATE)
  ]);
}
