import apiClient from 'services/axios';
import { replaceUrlIds } from 'utils';

const API = {
  list: 'api/veProfiles/{veProfileId}/devices',
  count: 'api/veProfiles/{veProfileId}/devices/count',
  assignDevices: 'api/veProfiles/{veProfileId}/devices'
};

export const list = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.list, [{ veProfileId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const count = async (id, filter) => {
  const parsedUrl = replaceUrlIds(API.count, [{ veProfileId: id }]);
  const response = await apiClient.get(parsedUrl, { params: filter });
  if (response) {
    return response.data;
  }
  return false;
};

export const batchAssignDevices = async (id, body) => {
  const parsedUrl = replaceUrlIds(API.assignDevices, [{ veProfileId: id }]);
  const response = await apiClient.put(parsedUrl, body);
  if (response.status === 204) {
    return true;
  }
  return false;
};
