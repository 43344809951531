import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import neProfile from './neProfile/reducer';
import veProfile from './veProfile/reducer';
import device from './device/reducer';
import profileDevice from './profileDevice/reducer';
import user from './user/reducer';
import firmware from './firmware/reducer';
import version from './version/reducer';

export default () =>
  combineReducers({
    toastr: toastrReducer,
    neProfile,
    veProfile,
    device,
    profileDevice,
    user,
    firmware,
    version
  });
